type StackedProps = {
  space?: number;
  className?: string;
  children: React.ReactNode;
};

export const Stacked = ({
  children,
  space = 20,
  className = '',
}: StackedProps) => {
  return (
    <div className={`grid grid-cols-1 gap-${space} self-start ${className}`}>
      {children}
    </div>
  );
};
